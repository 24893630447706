import { ElMessage } from 'element-plus'

const showTime = 5000

export function message(msg) {
    ElMessage({
        message: msg,
        duration: showTime,
    })
}

export function messageSuccess(msg) {
    ElMessage({
        message: msg,
        type: 'success',
        duration: showTime,
    })
}

export function messageWarning(msg) {
    ElMessage({
        message: msg,
        type: 'warning',
        duration: showTime,
    })
}

export function messageError(msg) {
    ElMessage({
        message: msg,
        type:"error",
        duration: showTime,
    })
}