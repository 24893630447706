export default {
    // app status
    appStatusOnline: 1,
    appStatusOffline: 2,

    // 推荐
    isRecommend: 1,
    notRecommend: 2,
    // 收藏
    isCollection: 1,
    notCollection: 2,

    // app list sort
    SortAsc: "asc",
    SortDesc: "desc",
    appListByLastUpdate: "last_update",
    appListByDownload: "download",
    appListBySize: "size",
    appListByRate: "rate",
    appListByTotalRate: "total_rate",

    // 权限
    permissionAdmin: "1",
    permissionUser: "2",
}
