import { delRole, getRole } from "./permission"

export function getUserId() {
    const userId = localStorage.getItem("userId")
    if (!userId) {
        return ""
    }
    return userId
}

export function getUserName() {
    const userId = localStorage.getItem("userId")
    if (!userId) {
        return ""
    }
    const userName = localStorage.getItem("user-" + String(userId) + "name")
    if (userName) {
        return userName
    }
    return ""
}

export function getToken() {
    const userId = localStorage.getItem("userId")
    if (!userId) {
        return ""
    }
    const token = localStorage.getItem("user-" + String(userId) + "-token")
    if (token) {
        return token
    }
    return ""
    // return "NmJmNzU4N2RjZDcwNGQ5ZmE5ZTNmYTMxM2RiZjVhNzBuNmRnYXQ2aGIzMmRwZTlwOHU4a2NhaW12M2hrc24tMw=="
}

export function setLoginInfo(data) {
    localStorage.setItem("userId", data.id);
    localStorage.setItem(
        "user-" + String(data.id) +"-token",
        data.token
    );
    localStorage.setItem("user-" + String(data.id) + "-name",
        data.account)
}

export function cleanLoginInfo() {
    const userId = localStorage.getItem("userId")
    if (!userId) {
        return
    }
    localStorage.removeItem("userId");
    localStorage.removeItem("user-" + String(userId));
    delRole(userId);
}

export function checkPermission(permission){
    if (permission ==  getRole(getUserId())){
        return true
    }
    return false
}