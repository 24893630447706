export function getRole(userId){
    const role = localStorage.getItem("role-"+String(userId))
    if (role==null){
        return ""
    }
    return role
}

export function setRole(userId, role){
    localStorage.setItem("role-"+String(userId), role)
}

export function delRole(userId){
    localStorage.removeItem("role-"+String(userId))
}